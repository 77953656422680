/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'comment_navy': {
    width: 15,
    height: 13,
    viewBox: '0 0 15 13',
    data: '<path pid="0" _fill="#1D1D44" d="M1.907 9.85C1.099 8.864.646 7.706.646 6.5c0-3.245 3.331-6 6.927-6 3.596 0 6.927 2.755 6.927 6s-3.331 6-6.927 6a7.425 7.425 0 01-2.984-.643c-.874.284-1.884.483-2.652.528-.451.026-.793.001-1.03-.103-.554-.243-.514-.812-.084-1.176l.217-.178.442-.352c.202-.162.364-.297.503-.424l.1-.094-.178-.208zm.182 1.52c.707-.066 1.611-.26 2.363-.523l.194-.067.185.088a6.452 6.452 0 002.742.632c3.08 0 5.927-2.354 5.927-5s-2.846-5-5.927-5c-3.081 0-5.927 2.354-5.927 5 0 1.158.53 2.28 1.476 3.193l.335.325-.3.357a6.11 6.11 0 01-.86.827l-.208.168z"/>'
  }
})
